import {
  AccountModelJSON,
  AddressModel, AddressModelJSON,
  ContactModel, ContactModelJSON,
  FileModel, FileModelJSON,
  ProductModel, ProductModelJSON,
  ShopDisplayIDModel,
} from '~/models'

export type ShopModelJSON = {
  displayID: string
  name: string
  description: string
  products?: ProductModelJSON[]
  address?: AddressModelJSON
  contact?: ContactModelJSON
  account?: AccountModelJSON
  files?: FileModelJSON[]
}

type ConstructorArgs = {
  displayID: ShopDisplayIDModel
  name: string
  description: string
  products: ProductModel[]
  address: AddressModel
  contact: ContactModel
  files: FileModel[]
}

export class ShopModel {
  public readonly displayID: ShopDisplayIDModel
  public readonly name: string
  public readonly description: string
  public readonly products: ProductModel[]
  public readonly address: AddressModel
  public readonly contact: ContactModel
  public readonly files: FileModel[]

  constructor(args: ConstructorArgs) {
    this.displayID = args.displayID
    this.name = args.name
    this.description = args.description
    this.products = args.products
    this.address = args.address
    this.contact = args.contact
    this.files = args.files
  }

  public toJSON(): ShopModelJSON {
    return {
      displayID: this.displayID.toJSON(),
      name: this.name,
      description: this.description,
      products: this.products.map(product => product.toJSON()),
      address: this.address.toJSON(),
      contact: this.contact.toJSON(),
    }
  }

  public static fromJSON(json: ShopModelJSON): ShopModel {
    if (!json.address || !json.contact) {
      throw new Error(`invalid json ${JSON.stringify(json)}`)
    }
    return new ShopModel({
      displayID: new ShopDisplayIDModel(json.displayID),
      name: json.name,
      files: json.files ? json.files.map(file => FileModel.fromJSON(file)) : [],
      description: json.description,
      products: json.products ? json.products.map(product => ProductModel.fromJSON(product)) : [],
      address: AddressModel.fromJSON(json.address),
      contact: ContactModel.fromJSON(json.contact),
    })
  }
}
