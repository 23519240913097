import type { FC, ReactNode } from 'react'
import { Link } from 'react-router';
import {
  BicycleModel,
  ProductModelJSON,
  ShopModel,
} from '~/models'

type CardProps = {
  children: ReactNode
}

export const Card: FC<CardProps> = ({ children }) =>
  <div className="w-5/6 sm:w-3/4 xl:w-3/5 p-5 bg-white rounded-lg border border-gray-700 shadow-black">
    {children}
  </div>

type TitleCardProps = {
  children: ReactNode
  title: string
}

export const TitleCard: FC<TitleCardProps> = ({ children, title }) =>
  <Card>
    <h1 className="text-center text-2xl my-5" >{title}</h1>
    {children}
  </Card>

type ProductCardProps = {
  assetsBaseURL: string
  product: ProductModelJSON
  className?: string
  newTab?: boolean
}

export const ProductCard: FC<ProductCardProps> = ({
  assetsBaseURL,
  product,
  className,
  newTab,
}) => {
  if (!product.bicycle) return null
  const src =
    product.files && product.files.length > 0
      ? `${assetsBaseURL}/${product.files.sort((a, b) => { return b.order = a.order })[0].path}?w=300&h=300`
      : 'https://placehold.jp/150x150.png?text=noimage'
  const to = `/products/${product.displayID}`
  const shop = product.shop ? ShopModel.fromJSON(product.shop) : undefined
  const bicycle = BicycleModel.fromJSON(product.bicycle)

  return (
    <Link
      to={to}
      target={newTab ? "_blank" : undefined}
      rel={newTab ? "noopener noreferrer" : undefined}
      className={`shadow shadow-gray-400 p-2 rounded flex flex-col w-full ${className}`}>
      <div className="overflow-clip flex justify-center align-center">
        <img className="h-36 w-auto object-contain" src={src} />
      </div>
      <div className="mt-1.5 flex justify-between flex-nowrap gap-2">
        <p className="text-xs whitespace-nowrap basis-3/12">{bicycle.year.value}</p>
        <p className="text-xs whitespace-nowrap text-right basis-8/12 overflow-hidden">{bicycle.maker.name}</p>
      </div>
      <div className="my-0.5">
        <h3 className="text-basic line-clamp-1 font-medium">{bicycle.name.value}</h3>
      </div>
      <div className="my-0.5">
        <p className="text-xs line-clamp-2">{product.description}</p>
      </div>
      <div className="my-0.5">
        <p className="text-xs line-clamp-2">{product.price ? `¥${product.price.toLocaleString()}` : '価格は要問い合わせ'}</p>
      </div>
      {shop &&
        <div className="my-0.5 mt-auto flex justify-between">
          <p className="text-xs whitespace-nowrap basis-3/12">{shop.address.prefectureCode.getDisplayName()}</p>
          <p className="text-xs whitespace-nowrap text-right basis-8/12 overflow-hidden">{shop.name}</p>
        </div>
      }
      <div className="w-full overflow-clip flex justify-center align-center">
        <span className="w-full text-center m-auto bg-primary text-white py-1">
          詳細を見る
        </span>
      </div>
    </Link>
  )
}
