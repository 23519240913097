import type { FC, ReactNode } from 'react'
import { Link } from 'react-router';
import {
  ShopModel,
  ShopModelJSON,
} from '~/models'

type CardProps = {
  children: ReactNode
}

export const Card: FC<CardProps> = ({ children }) =>
  <div
    className={`
    w-5/6 sm:w-3/4 xl:w-3/5 p-5 bg-white
    rounded-lg border border-gray-700 shadow-black
    `}>
    {children}
  </div>

type TitleCardProps = {
  children: ReactNode
  title: string
}

export const TitleCard: FC<TitleCardProps> = ({ children, title }) =>
  <Card>
    <h1 className="text-center text-2xl my-5">{title}</h1>
    {children}
  </Card>

type ShopCardProps = {
  assetsBaseURL: string
  shop: ShopModelJSON
  className?: string
}

export const ShopCard: FC<ShopCardProps> = ({
  assetsBaseURL,
  shop,
  className,
}) => {
  const src =
    shop.files && shop.files.length > 0
      ? `${assetsBaseURL}/${shop.files.sort((a, b) => { return b.order = a.order })[0].path}?w=300&h=300`
      : 'https://placehold.jp/150x150.png?text=noimage'
  const to = `/shops/${shop.displayID}`
  const shopModel = ShopModel.fromJSON(shop)

  return (
    <Link
      to={to}
      className={`shadow shadow-gray-400 p-2 rounded flex flex-col w-full ${className}`}>
      <div className="overflow-clip flex justify-center align-center">
        <img className="h-36 w-auto object-contain" src={src} />
      </div>
      <div className="my-0.5">
        <h3 className="text-basic line-clamp-1 font-medium">{shopModel.name}</h3>
      </div>
      <div className="my-0.5">
        <p className="text-xs line-clamp-2">{`${shopModel.address.prefectureCode.getDisplayName()} ${shopModel.address.city}`}</p>
      </div>
      <div className="my-0.5 grow">
        <p className="text-xs line-clamp-2">{shopModel.description}</p>
      </div>
      <div className="w-full overflow-clip flex justify-center align-center">
        <span className="w-full text-center m-auto bg-primary text-white py-1">
          詳細を見る
        </span>
      </div>
    </Link>
  )
}
