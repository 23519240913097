import {
  BicycleModel,
  BicycleModelJSON,
  PartModel,
  PartModelJSON,
  SupplyModel,
  SupplyModelJSON,
  FileModel,
  FileModelJSON,
  ProductTypeModel,
  ProductDisplayIDModel,
  ShopModel,
  ShopModelJSON,
} from '~/models'

export type ProductModelJSON = {
  displayID: string
  description: string
  type: number
  new: boolean
  public: boolean
  publicationEnded: boolean
  cartEnabled: boolean
  shop?: ShopModelJSON
  bicycle?: BicycleModelJSON
  part?: PartModelJSON
  supply?: SupplyModelJSON
  files?: FileModelJSON[]
  publicationStartUnixTime?: string
  publicationEndUnixTime?: string
  price?: number
  minTargetHeight?: number
  maxTargetHeight?: number
  stock: number
  isCashOnDelivery: boolean
}

export type ProductModelArgs = {
  displayID: ProductDisplayIDModel
  description: string
  type: ProductTypeModel
  new: boolean
  public: boolean
  publicationEnded: boolean
  cartEnabled: boolean
  shop?: ShopModel
  bicycle?: BicycleModel
  part?: PartModel
  supply?: SupplyModel
  files?: FileModel[]
  publicationStartUnixTime?: string
  publicationEndUnixTime?: string
  price?: number
  minTargetHeight?: number
  maxTargetHeight?: number
  stock: number
  isCashOnDelivery: boolean
}

export class ProductModel {
  public readonly displayID: ProductDisplayIDModel
  public readonly description: string
  public readonly new: boolean
  public readonly public: boolean
  public readonly publicationEnded: boolean
  public readonly cartEnabled: boolean
  public readonly type: ProductTypeModel
  public readonly bicycle?: BicycleModel
  public readonly part?: PartModel
  public readonly supply?: SupplyModel
  public readonly files: FileModel[]
  public readonly publicationStartUnixTime?: string
  public readonly publicationEndUnixTime?: string
  public readonly price?: number
  public readonly minTargetHeight?: number
  public readonly maxTargetHeight?: number
  public readonly stock: number
  public readonly isCashOnDelivery: boolean

  constructor(args: ProductModelArgs) {
    if (!args.files)
      throw new Error(`invalid args ${JSON.stringify(args)}`)
    if (
      args.minTargetHeight && args.maxTargetHeight
      && (
        args.minTargetHeight <= 0
        || args.maxTargetHeight <= 0
        || (args.maxTargetHeight <= args.minTargetHeight)
      ))
      throw new Error(`invalid args ${JSON.stringify(args)}`)
    this.displayID = args.displayID
    this.description = args.description.trim()
    this.new = args.new
    this.public = args.public
    this.publicationEnded = args.publicationEnded
    this.cartEnabled = args.cartEnabled
    this.type = args.type
    this.bicycle = args.bicycle
    this.part = args.part
    this.supply = args.supply
    this.files = args.files
    this.publicationStartUnixTime = args.publicationStartUnixTime
    this.publicationEndUnixTime = args.publicationEndUnixTime
    this.price = args.price
    this.minTargetHeight = args.minTargetHeight
    this.maxTargetHeight = args.maxTargetHeight
    this.stock = args.stock
    this.isCashOnDelivery = args.isCashOnDelivery
  }

  public toJSON(): ProductModelJSON {
    return {
      displayID: this.displayID.value,
      description: this.description,
      type: this.type.toJSON(),
      new: this.new,
      public: this.public,
      publicationEnded: this.publicationEnded,
      cartEnabled: this.cartEnabled,
      bicycle: this.bicycle ? this.bicycle.toJSON() : undefined,
      part: this.part ? this.part.toJSON() : undefined,
      supply: this.supply ? this.supply.toJSON() : undefined,
      files: this.files.map(file => file.toJSON()),
      publicationStartUnixTime: this.publicationStartUnixTime,
      publicationEndUnixTime: this.publicationEndUnixTime,
      price: this.price,
      minTargetHeight: this.minTargetHeight,
      maxTargetHeight: this.maxTargetHeight,
      stock: this.stock,
      isCashOnDelivery: this.isCashOnDelivery,
    }
  }

  public static fromJSON(json: ProductModelJSON): ProductModel {
    return new ProductModel({
      displayID: new ProductDisplayIDModel(json.displayID),
      description: json.description,
      type: new ProductTypeModel(json.type),
      new: json.new,
      public: json.public,
      publicationEnded: json.publicationEnded,
      cartEnabled: json.cartEnabled,
      bicycle: json.bicycle ? BicycleModel.fromJSON(json.bicycle) : undefined,
      part: json.part ? PartModel.fromJSON(json.part) : undefined,
      supply: json.supply ? SupplyModel.fromJSON(json.supply) : undefined,
      files: json.files ? json.files.map(file => FileModel.fromJSON(file)) : [],
      publicationStartUnixTime: json.publicationStartUnixTime,
      publicationEndUnixTime: json.publicationEndUnixTime,
      price: json.price,
      minTargetHeight: json.minTargetHeight,
      maxTargetHeight: json.maxTargetHeight,
      stock: json.stock,
      isCashOnDelivery: json.isCashOnDelivery,
    })
  }
}
