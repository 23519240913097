import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useMatches,
  useRouteLoaderData,
  useLocation,
  useRouteError,
  isRouteErrorResponse,
} from "react-router";
import type { Route } from "./+types/root";
import { LoaderFunction } from "react-router";
import type { LinksFunction } from "react-router";
import { createContext, useEffect, useState } from "react";
import { ErrorLayout } from "~/components/templates/user/error";
import { LogoHeader } from "~/components/compounds/user/logo-header";
import { Footer } from "~/components/footer";
import stylesheet from "~/styles/app.css?url";
import {
  getGTMContainerID,
  getAppBaseURL,
  getBrowserEnvironment,
} from "~/utils/application.server";
import type { BrowserEnvironment } from "~/utils/application";
import { MakerModelJSON } from "~/models";
import { MakerUseCase } from "~/usecases/maker.server";

export const links: Route.LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },
];

export type RootContextType = {
  isScrollable: boolean;
  setIsScrollable: (isMenuOpened: boolean) => void;
  makers: MakerModelJSON[];
};

type LoaderData = {
  gtmContainerID: string;
  appBaseURL: string; // to use meta tag
  makers: MakerModelJSON[];
  browserEnvironment: BrowserEnvironment;
};

export const loader: LoaderFunction = async () => {
  const makerUseCase = new MakerUseCase();
  const makerModels = await makerUseCase.findAll();
  return ({
    gtmContainerID: getGTMContainerID(),
    appBaseURL: getAppBaseURL(),
    makers: makerModels,
    browserEnvironment: getBrowserEnvironment(),
  });
};

export const RootContext = createContext<RootContextType>({
  setIsScrollable: (isScrollable: boolean) => { },
  isScrollable: false,
  makers: [],
});

declare global {
  interface Window {
    dataLayer?: object[];
  }
}

// const initSentry = (browserEnvironment: BrowserEnvironment) => {
//   Sentry.init({
//     dsn: browserEnvironment.SENTRY_DSN,
//     tracesSampleRate: 1,
//     environment: browserEnvironment.APP_ENV,
// 
//     integrations: [
//       Sentry.browserTracingIntegration({
//         useEffect,
//         useLocation,
//         useMatches,
//       }),
//       Sentry.replayIntegration({
//         maskAllText: true,
//         blockAllMedia: true,
//       }),
//     ],
// 
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1,
//   });
// }

const initGTM = () => {
  window.dataLayer = window.dataLayer || [];
  if (!window.dataLayer.find((el) => "gtm.start" in el)) {
    window.dataLayer.push({
      event: "gtm.js",
      "gtm.start": new Date().getTime(),
    });
  }
}

export function Layout({ children }: { children: React.ReactNode }) {
  const loaderData =
    useRouteLoaderData<LoaderData>('root')
  const [isScrollable, setIsScrollable] = useState<boolean>(true);

  useEffect(() => {
    if (loaderData?.browserEnvironment.APP_ENV === 'development') return
    initGTM()
    // initSentry(browserEnvironment)
  }, []);

  return (
    <html lang="ja">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <script
          async={true}
          src={`https://www.googletagmanager.com/gtm.js?id=${loaderData?.gtmContainerID ?? ''}`}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(loaderData?.browserEnvironment)}`,
          }}
        />
        <Meta />
        <Links />
      </head>
      <body className={`${isScrollable ? "" : "overflow-hidden"}`}>
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${loaderData?.gtmContainerID ?? ''}" height="0" width="0" style="display: none, visibility: hidden"></iframe>`,
          }}
        />
        <RootContext.Provider
          value={{
            setIsScrollable: setIsScrollable,
            isScrollable: isScrollable,
            makers: loaderData?.makers ?? [],
          }}
        >
          {children}
          <ScrollRestoration />
          <Scripts />
        </RootContext.Provider>
      </body>
    </html>
  );
}

export default function App() {
  return <Outlet />;
}

export function ErrorBoundary({ error }: Route.ErrorBoundaryProps) {

  return (
    <ErrorLayout
      header={<LogoHeader />}
      footer={<Footer />}
      children={
        isRouteErrorResponse(error) && error.status == 404 ? (
          <div className="p-4">
            <h2 className="text-lg text-center">
              お探しのページが見つかりません
            </h2>
          </div>
        ) : (
          <div className="p-4">
            <h2 className="text-lg text-center">エラーが発生しました</h2>
            <p className="my-4">
              お手数ですがしばらくしてからアクセスしてください。
            </p>
          </div>
        )
      }
    />
  );
}
