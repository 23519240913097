import type { FC } from 'react'
import { Link } from 'react-router';

import path from '~/constants/path'

export type FooterProps = {
  className?: string
}

export const Footer: FC<FooterProps> = (
  className,
) => {
  return (
    <div className={`flex flex-wrap justify-center bg-primary p-2 w-full ${className}`}>
      <p className="text-white basis-full text-center">
        <Link to={path.user.home}>
          Cycle Mikke
        </Link>
      </p>
      <ul className="mx-auto my-2 text-xs text-gray-300 flex flex-row gap-3 md:gap-4">
        <li><Link className="" to={path.user.company.overview} >会社概要</Link></li>
        <li><Link className="" to={path.user.company.policies.privacy} >プライバシーポリシー</Link></li>
        <li><Link className="" to={path.user.company.policies.terms} >利用規約</Link></li>
        <li><a className="" href={path.admin.auth.login} >ショップ管理サイト</a></li>
      </ul>
    </div>
  )
}
