import type { KeyboardEventHandler, MouseEventHandler, FC } from "react"
import { Link } from "react-router";

type ButtonProps = {
  type?: "submit" | "reset" | "button"
  children: string
  form?: string
  disabled?: boolean
  className?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  onKeyDown?: KeyboardEventHandler<HTMLButtonElement>
}

export const Button: FC<ButtonProps> = ({
  children,
  type = 'button',
  disabled = false,
  className = '',
  form,
  onClick,
  onKeyDown = undefined,
}) =>
  <button
    type={type}
    disabled={disabled}
    className={
      `text-white font-bold py-2 px-4 rounded w-full
      ${disabled ? 'bg-gray-400 hover:bg-gray-400' : 'bg-secondary hover:bg-blue-700'}
      ${className}`
    }
    form={form}
    onClick={onClick}
    onKeyDown={onKeyDown}
  >{children}</button>

type SelectButtonProps = ButtonProps

export const SelectButton: FC<SelectButtonProps> = ({
  children,
  type = 'button',
  disabled = false,
  className = '',
  onClick,
  onKeyDown = undefined,
}) =>
  <button
    type={type}
    disabled={disabled}
    className={`
      hover:bg-gray-400 border border-gray-500 py-1 px-4 rounded w-full
      ${className}
    `}
    onClick={onClick}
    onKeyDown={onKeyDown}
  >{children}</button>

type SelectLinkButtonProps = {
  to: string
  children?: string
  className?: string
  isSelected?: boolean,
}

export const SelectLinkButton: FC<SelectLinkButtonProps> = ({
  to,
  className,
  children,
  isSelected,
}) =>
  <Link
    className={
      `hover:bg-gray-300 border border-gray-300 py-1 px-4 rounded w-full
      ${isSelected ? 'bg-gray-300' : ''}
      ${className}`
    }
    to={to}>{children}</Link>
