import type { MetaFunction } from 'react-router';

export type AppEnv =
  | 'production'
  | 'staging'
  | 'development'
  | 'test'

export const isValidAppEnv = (name?: string): name is AppEnv => {
  if (name == null) return false
  switch (name) {
    case 'production':
    case 'staging':
    case 'development':
    case 'test':
      return true
  }
  return false
}

export type BrowserEnvironment = {
  APP_BASE_URL: string
  APP_ASSETS_BASE_URL: string
  APP_INFO_EMAIL: string
  APP_ENV: AppEnv
  SENTRY_DSN: string
}

declare global {
  interface Window { ENV: BrowserEnvironment; }
}

export const isBrowser: () => boolean = () =>
  typeof window !== 'undefined'

export const isProd: () => boolean = () =>
  isBrowser() ? window?.ENV?.APP_ENV === 'production' : process.env.APP_ENV === 'production'

export const getAppInfoEmail: () => string | undefined = () =>
  isBrowser() ? window.ENV.APP_INFO_EMAIL : process.env.APP_INFO_EMAIL

export const getAppAssetsBaseURL: () => string = () => {
  const url = isBrowser() ? window.ENV.APP_ASSETS_BASE_URL : process.env.APP_ASSETS_BASE_URL
  if (!url) throw new Error('APP_ASSETS_BASE_URL is undefined')
  return url
}

/*
 * https://remix.run/docs/en/v1/api/conventions
 * The meta function may run on the server (e.g. the initial page load) or the client (e.g. a client navigation), so you cannot access server-specific data like process.env.NODE_ENV directly. If you need server-side data in meta, get the data in the loader and access it via the meta function's data parameter.
*/
type CreateMeta = {
  title: string,
  isShortedTitle?: boolean,
  description?: string,
  keywords?: string,
  ogImage?: string,
  ogType?: string,
  twitterCard?: string,
}
export const createMeta = ({
  location,
  title,
  isShortedTitle,
  description,
  keywords,
  ogImage,
  ogType,
  twitterCard,
}: Parameters<MetaFunction>[0] & CreateMeta): ReturnType<MetaFunction> => {
  const siteName = isShortedTitle ? 'サイクルミッケ' : 'Cycle Mikke -サイクルミッケ-'
  const metaDescription = description ?? 'サイクルミッケは全国のサイクルショップが出店する自転車専用ポータルサイトです。希少品や掘り出し物、最新の在庫情報など、一堂に掲載されます。今まで探していたものが見つかるかも！“探してみよう、サイクルミッケ！”'
  const metaTitle = isShortedTitle ? `${title} | ${siteName}` : `${siteName} | ${title}`
  const metaKeywords = `Cycle Mikke,サイクルミッケ${keywords ? (',' + keywords) : ''}`
  const appBaseURL = 'https://cyclemikke.com'
  return [
    { charset: 'utf-8' },
    { viewport: 'width=device-width,initial-scale=1' },
    { title: metaTitle },
    { name: "description", content: metaDescription },
    { name: "keywords", content: metaKeywords },
    { name: "twitter:card", content: twitterCard ?? 'summary' },
    { property: "og:url", content: `${appBaseURL}${location.pathname}` },
    { property: "og:site_name", content: siteName },
    { property: "og:image", content: ogImage ?? `${appBaseURL}/images/logo_1.png` },
    { property: "og:title", content: metaTitle },
    { property: "og:description", content: metaDescription },
    { property: "og:type", content: ogType ?? undefined },
  ]
}
