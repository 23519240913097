import { useContext, useState } from 'react'
import type { FC } from 'react'
import { Form, Link, useLocation, useNavigate, useSearchParams } from 'react-router';
import Logo from '~/assets/images/svg/logo_2.svg'
import { RootContext } from '~/root'
import { HeaderSelectBox, HeaderSelectOptions } from '~/components/inputs'
import { SelectLinkButton } from '~/components/buttons'
import {
  BicycleGenreModel,
  PartGenreModel,
  SupplyGenreModel,
  PrefectureCodeModel,
} from '~/models'
import path from '~/constants/path'

type HeaderProps = {
  className: string
  isAuthenticated: boolean
}

export const keys = {
  type: 'user-header-type-key',
  search: 'user-header-search-key',
  height: 'user-header-height-key',
  maker: 'user-hedaer-maker-key',
  prefecture: 'user-hedaer-prefecture-key',
  genre: 'user-hedaer-genre-key',
  minPrice: 'user-hedaer-min-price-key',
  maxPrice: 'user-hedaer-max-price-key',
}

const priceRangeList = {
  0: { min: undefined, max: 10_000, option: '~10万円' },
  1: { min: 10_000, max: 20_000, option: '10万円~20万円' },
  2: { min: 20_000, max: 30_000, option: '20万円~30万円' },
  3: { min: 30_000, max: 40_000, option: '30万円~40万円' },
  4: { min: 40_000, max: 50_000, option: '40万円~50万円' },
  5: { min: 50_000, max: 60_000, option: '50万円~60万円' },
  6: { min: 60_000, max: 70_000, option: '60万円~70万円' },
  7: { min: 70_000, max: 80_000, option: '70万円~80万円' },
  8: { min: 80_000, max: 90_000, option: '80万円~90万円' },
  9: { min: 90_000, max: 100_000, option: '90万円~100万円' },
  10: { min: 100_000, max: undefined, option: '100万円~' },
}

const typeOptions = {
  0: {
    option: '自転車',
  },
  1: {
    option: 'パーツ',
  },
  2: {
    option: '用品',
  }
}

const getGenreOptions = (type: string | undefined) => {
  if (!type) return {}
  switch (type) {
    case '0':
      return BicycleGenreModel.list().reduce<HeaderSelectOptions>((pre, [key, val]) => ({
        ...pre,
        [key]: { option: val },
      }), {})
    case '1':
      return PartGenreModel.list().reduce<HeaderSelectOptions>((pre, [key, val]) => ({
        ...pre,
        [key]: { option: val },
      }), {})
    case '2':
      return SupplyGenreModel.list().reduce<HeaderSelectOptions>((pre, [key, val]) => ({
        ...pre,
        [key]: { option: val },
      }), {})
  }
  throw new Error(`unknown type: ${type}`)
}

// Create [minPrice, maxPrice] from priceRangeList
const getRange = (key: number): [number?, number?] => {
  if (key === 0) return [undefined, 100_000]
  if (key === 10) return [1_000_000, undefined]
  return [key * 100_000, (key + 1) * 100_000]
}

// Get value of priceRangeList
const getKey = (minPrice?: number, maxPrice?: number): string | undefined => {
  const found = Object.entries(priceRangeList)
    .find(([_, obj]) => (obj.min === minPrice && obj.max === maxPrice))
  return found ? found[0] : undefined
}

export const Header: FC<HeaderProps> = ({
  className = '',
  isAuthenticated,
}) => {
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const defaultKeywords = (() => {
    const arr = location.pathname.split('/')
    const keywords = decodeURI(arr[arr.length - 1])
    const search = decodeURI(arr[arr.length - 2])
    return keywords && keywords !== 'search' && search && search === 'search' ? keywords : ''
  })()
  const [keywords, setKeywords] = useState<string>(defaultKeywords)
  const type = searchParams.get(keys.type) ?? undefined
  const minPrice = searchParams.get(keys.minPrice) ?? undefined
  const maxPrice = searchParams.get(keys.maxPrice) ?? undefined
  const height = searchParams.get(keys.height) ?? undefined
  const maker = searchParams.get(keys.maker) ?? undefined
  const prefecture = searchParams.get(keys.prefecture) ?? undefined
  const genre = searchParams.get(keys.genre) ?? undefined
  const { makers } = useContext(RootContext)
  const navigate = useNavigate()
  const createURL = ({
    t = type,
    h = height,
    m = maker,
    p = prefecture,
    g = genre,
    min = minPrice,
    max = maxPrice,
  }: {
    t?: string,
    h?: string,
    m?: string,
    p?: string,
    g?: string,
    min?: string,
    max?: string,
  }): string => {
    const url = `/search/${keywords}?${keys.type}=${t ?? ''}&${keys.height}=${h ?? ''}&${keys.maker}=${m ?? ''}&${keys.prefecture}=${p ?? ''}&${keys.genre}=${g ?? ''}&${keys.minPrice}=${min ?? ''}&${keys.maxPrice}=${max ?? ''}`
    return url
  }

  return (
    <header className="flex flex-col" >
      <div className={`flex flex-col shadow bg-white md:flex-row w-full items-center md:justify-between p-2 h-auto md:h-24 ${className}`}>
        <Link to="/" className="h-full w-5/12 md:basis-1/3 flex justify-center align-center" >
          <img className="m-auto h-7 md:h-auto" src={Logo} />
        </Link>
        <div
          className="w-full md:basis-6/12"
        >
          <Form
            className="w-full my-2"
            method="GET"
            onSubmit={e => {
              e.preventDefault()
              return navigate(createURL({}))
            }}
          >
            <div className="h-full flex contents-center" >
              <input
                id="search"
                name="search"
                value={keywords}
                onChange={event => setKeywords(event.target.value)}
                placeholder="キーワードで検索: 名古屋 giant 2019"
                className="w-full m-auto p-2 rounded shadow-lg placeholder:gray-400 placeholder:text-xs bg-[url('/images/svg/icon/search.svg')] bg-no-repeat bg-[top_50%_right_5%]" />
            </div>
          </Form>
          <div className="flex justify-end gap-2">
            <div className="my-2">
              <SelectLinkButton to={path.user.cart.home} className="text-center w-fit text-xs">カートを見る</SelectLinkButton>
            </div>
            {isAuthenticated ? (
              <div className="my-2">
                <SelectLinkButton to={path.user.mypage.invoices.home} className="text-center w-fit text-xs">マイページ</SelectLinkButton>
              </div>
            ) : (
              <div className="my-2">
                <SelectLinkButton to={path.user.auth.login} className="text-center w-fit text-xs">ログイン</SelectLinkButton>
              </div>
            )}
          </div>
        </div>
      </div>
      <nav >
        <ul className="flex flex-wrap lg:flex-nowrap justify-center pt-2 md:pb-2 md:mb-2">
          <li className="basis-1/2 md:basis-1/3 lg:grow hover:bg-gray-200 border-x">
            <HeaderSelectBox
              isVertical={false}
              defaultKey={type}
              onChange={t => navigate(createURL({
                t: t ?? '',
                h: '',
                g: '',
              }),
                { replace: true },
              )}
              label="タイプ"
              options={typeOptions}
            />
          </li>
          <li className="basis-1/2 md:basis-1/3 lg:grow hover:bg-gray-200 border-x">
            <HeaderSelectBox
              isVertical={false}
              defaultKey={prefecture}
              onChange={v => navigate(createURL({ p: v ?? '' }), { replace: true })}
              label="地域"
              options={
                PrefectureCodeModel.list().reduce<HeaderSelectOptions>((pre, [key, val]) => ({
                  ...pre,
                  [key]: { option: val },
                }), {})}
            />
          </li>
          <li className="basis-1/2 md:basis-1/3 lg:grow hover:bg-gray-200 border-x">
            <HeaderSelectBox
              isVertical={false}
              defaultKey={maker}
              onChange={v => navigate(createURL({ m: v ?? '' }), { replace: true })}
              label="メーカー"
              options={
                makers.sort((a, b) => a.name < b.name ? -1 : 1)
                  .reduce<HeaderSelectOptions>((pre, maker) =>
                    ({ ...pre, [maker.displayID]: { option: maker.name } }), {})
              }
            />
          </li>
          <li className="basis-1/2 md:basis-1/3 lg:grow hover:bg-gray-200 border-x">
            <HeaderSelectBox
              isVertical={false}
              defaultKey={height}
              onChange={v => navigate(createURL({ h: v ?? '' }), { replace: true })}
              label="身長"
              suffix="cm"
              options={
                [...Array(75).keys()].reduce<HeaderSelectOptions>(((pre, key) => ({
                  ...pre,
                  [`${(135 + key)}`]: {
                    option: `${(135 + key)}`,
                  }
                })), {})}
              disabled={type !== '0'}
            />
          </li>
          <li className="basis-1/2 md:basis-1/3 lg:grow hover:bg-gray-200 border-x">
            <HeaderSelectBox
              isVertical={false}
              defaultKey={genre}
              onChange={v => navigate(createURL({ g: v ?? '' }), { replace: true })}
              label="ジャンル"
              options={getGenreOptions(type)}
              disabled={!(type && type in ['0', '1', '2'])}
            />
          </li>
          <li className="basis-1/2 md:basis-1/3 lg:grow hover:bg-gray-200 border-x">
            <HeaderSelectBox
              isVertical={false}
              defaultKey={getKey(Number(minPrice), Number(maxPrice))}
              onChange={v => {
                const [min, max] = v ? getRange(Number(v)) : [undefined, undefined]
                return navigate(createURL({ min: String(min ?? ''), max: String(max ?? '') }), { replace: true })
              }}
              label="価格"
              options={priceRangeList}
            />
          </li>
        </ul>
      </nav>
    </header>
  )
}
